import { CalendarRoutesEnum } from '@/modules/calendar/router';
import { CouponsRoutesEnum } from '@/modules/coupons/router/CouponsRoutesEnum';
import { CustomersRoutesEnum } from '@/modules/customers/router';
import { OrdersRoutesEnum } from '@/modules/orders/router';
import { ProviderServicesRoutesEnum } from '@/modules/services/router/providerPortfolioServices';
import { TechniciansRoutesEnum } from '@/modules/technicians/router';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'ProviderDashboard'
})
export default class ProviderDashboard extends Vue {

    get calendarRoute() {
        return { 
            name: CalendarRoutesEnum.CALENDAR 
        };
    }
    
    get servicesRoute() {
        return { 
            name: ProviderServicesRoutesEnum.PORTFOLIO_SERVICES_LIST 
        };
    }

    get feedbacksRoute() {
        return { 
            name: ProviderServicesRoutesEnum.SERVICES_FEEDBACKS_LIST 
        };
    }

    get techniciansRoute() {
        return {
            name: TechniciansRoutesEnum.TECHNICIAN_LIST
        }
    }

    get ordersRoute() {
        return {
            name: OrdersRoutesEnum.ORDERS
        }
    }

    get customersRoute() {
        return {
            name: CustomersRoutesEnum.CUSTOMERS
        }
    }

    goIssuedCoupons() {
        this.$router.push({ name: CouponsRoutesEnum.ISSUED_COUPONS });
    }
}