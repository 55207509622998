import { conditionsService } from "@services/conditions.service";
import { CalendarRoutesEnum } from '@/modules/calendar/router';
import { POSRoutesEnum } from '@/modules/pos/router';
import { ProviderServicesRoutesEnum } from '@/modules/services/router/providerPortfolioServices';
import { Options, Vue } from 'vue-class-component';
import Chart from 'primevue/chart';
import { ref } from 'vue'
import { CouponsRoutesEnum } from '@/modules/coupons/router';
import { MainRoutesEnum } from '@/router/MainRoutesEnum';
import { PosCalendar as PosCalendarComponent } from '@/components';
import { Notifications as NotificationsComponent } from '@/components';
import { Condition } from "@/model/api/Condition";
import { Prop } from "vue-property-decorator/lib/decorators/Prop";

@Options({
    name: 'PosDashboard',
    components: {
        PosCalendarComponent,
        NotificationsComponent
      }
  })
  
export default class POSDashboard extends Vue {
    @Prop({ default: 'false' }) fromLogin: string;
    @Prop({ default: 5 }) shown: number;

    conditions: Condition[] = null;
    visible: boolean = false;

    goCalendar() {
        this.$router.push({ 
            name: CalendarRoutesEnum.POS_CALENDAR ,
            params: {
                forcustomer: 0
              }
        });
    }

    goBookCustomerService() {
        this.$router.push({ 
            name: CalendarRoutesEnum.POS_CALENDAR,
            params: {
                forcustomer: 1
              }
        });
    }

    goBuyProviderService() {
        this.$router.push({ name: POSRoutesEnum.ADD_PROVIDER_SERVICE });
    }

    goPortfolio() {
        this.$router.push({ name: POSRoutesEnum.PORTFOLIO_LIST });
    }

    goServices() {
        this.$router.push({ name: POSRoutesEnum.SERVICES_LIST });
    }

    goOrders() {
        this.$router.push({ name: POSRoutesEnum.POS_ORDERS });
    }

    goCustomerOrders() {
        this.$router.push({ name: POSRoutesEnum.POS_CUSTOMER_ORDERS });
    }

    goFeedbacks() {
        this.$router.push({ name: POSRoutesEnum.POS_CUSTOMER_FEEDBACKS });
    }

    goIssuedCoupons() {
        this.$router.push({ name: CouponsRoutesEnum.ISSUED_COUPONS });
    }

    goPromoCoupons() {
        this.$router.push({ name: CouponsRoutesEnum.ISSUED_COUPONS });
    }

    goNotifications() {
        this.$router.push({ name: MainRoutesEnum.NOTIFICATIONS });
    }

    goMyCoupons() {
        this.$router.push({ name: CouponsRoutesEnum.COUPONS });
    }

    goGraphs() {
        this.$router.push({ name: POSRoutesEnum.POS_GRAPHS });
    }

    goAddNewService() {
        this.$router.push({ name: POSRoutesEnum.ADD_SERVICE_SELECT });
    }

    goInvoices() {
        this.$router.push({ name: POSRoutesEnum.INVOICES });
    }

    created() {
        if (this.fromLogin != "false" ){
            this.loadConditions();
        }
    }

    async loadConditions() {        
        const response = await conditionsService.fetchConditions();
        this.conditions = response.slice(0, this.shown);
        if (this.conditions.length != 0)
            this.visible = true;
    }

    refuse(){
        this.$localStorage.clearToken();
        window.location.reload();
    }

    async accept(){
        const response = await conditionsService.accept();
        this.visible = false;
    }
}
