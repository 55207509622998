
import { notificationsService } from "@services/notifications.service";
import { ref, defineComponent } from "vue";

import { Notification } from "@/model/api/Notification";
import moment from "moment";
import { useRouter } from "vue-router";
import { ProviderTechnicianRoutesEnum } from "@/modules/provider-technician/router";
import { NotificationEnum } from "@/model/enums/NotificationEnum";
import { POSRoutesEnum } from "@/modules/pos/router";
import { OrdersRoutesEnum } from "@/modules/orders/router";
import { ProviderServicesRoutesEnum } from '@/modules/services/router/providerPortfolioServices';

export default defineComponent({
  setup() {
    const r = useRouter();
    const notifications = ref<Notification[]>()

    function dateFormatted(date) {
      if (moment().isSame(date, "day")) {
        return `Oggi, ${moment(date).tz('UTC').format("HH:mm")}`;
      } else {
        return moment(date).tz('UTC').format("DD/MM/YYYY HH:mm");
      }
    }

    function goToNotification(notification: Notification) {
      
      console.debug("Clicked", notification);
      notificationsService.setRead(notification.id);

      switch(notification.type) {
        case NotificationEnum.POS_CUSTOMER_ORDER:
          r.push({ name: OrdersRoutesEnum.ORDER_DETAILS, params: {orderId: notification.object_id}});
          break;
       case NotificationEnum.POS_POS_ORDER:
          r.push({ name: POSRoutesEnum.PORTFOLIO_EDIT, params: {portfolioId: notification.object_id}});
          break;
        case NotificationEnum.PROVIDER_POS_ORDER:
          r.push({ name: OrdersRoutesEnum.ORDER_DETAILS, params: {orderId: notification.object_id}});
          break;
        case NotificationEnum.PROVIDER_FEEDBACK_RECEIVED:
          r.push({ name: ProviderServicesRoutesEnum.SERVICES_FEEDBACKS_LIST });
          break;
        case NotificationEnum.POS_FEEDBACK_RECEIVED:
          r.push({ name: POSRoutesEnum.POS_CUSTOMER_FEEDBACKS });
          break;
        case NotificationEnum.TECHNICIAN_POS_ORDER:
        default:
          r.push({ name: ProviderTechnicianRoutesEnum.APPOINTMENTS });
          break;
      }
    }

    async function loadNotifications() {
      const response = await notificationsService.fetchNotifications('false');

      notifications.value = response;
    }

    /*function setReadAll() {
      notificationsService.setReadAll();
    }*/

    loadNotifications();
    //setReadAll();
    

    return {
      notifications,
      dateFormatted,
      goToNotification
    }
    
  },

  mounted() {
    this.$store.dispatch("updateNotificationCount");
  },

  unmounted() {
    this.$store.dispatch("updateNotificationCount");
  }
})
