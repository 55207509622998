import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "content card" }
const _hoisted_5 = { class: "p-field p-col-6" }
const _hoisted_6 = { class: "p-field p-col-6" }
const _hoisted_7 = { class: "p-field p-col-6" }
const _hoisted_8 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_VField = _resolveComponent("VField")!
  const _component_VForm = _resolveComponent("VForm")!

  return (_openBlock(), _createBlock(_component_VForm, {
    as: "div",
    id: "profile_page",
    class: "page standard vertical-form"
  }, {
    default: _withCtx(({ handleSubmit, isSubmitting, meta: { valid } }) => [
      _createVNode("section", _hoisted_1, [
        _createVNode("div", null, [
          _createVNode("h1", null, _toDisplayString(_ctx.$t('profile.title')), 1),
          _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('profile.subtitle')), 1)
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            label: _ctx.$t('profile.btn_change_password_label'),
            class: "p-button-secondary",
            icon: "pi pi-key",
            iconPos: "right",
            onClick: _ctx.goChangePassword
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('btn_save_label'),
            class: "p-button-success",
            icon: "pi pi-save",
            iconPos: "right",
            disabled: !valid || isSubmitting,
            type: "submit",
            form: "profile_form"
          }, null, 8, ["label", "disabled"])
        ])
      ]),
      _createVNode("section", _hoisted_4, [
        _createVNode("form", {
          class: "form_content",
          onSubmit: ($event: any) => (handleSubmit($event, _ctx.doSave)),
          id: "profile_form"
        }, [
          _createVNode("div", _hoisted_5, [
            _createVNode("label", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('user_name')) + " ", 1),
              _createVNode(_component_InfoIcon, { text: "user_name.tooltip" })
            ]),
            _createVNode(_component_VField, {
              autofocus: "",
              name: "name",
              modelValue: _ctx.me.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.me.name = $event)),
              rules: "required"
            }, {
              default: _withCtx(({ field, meta: { dirty, valid } }) => [
                _createVNode(_component_InputText, _mergeProps(field, {
                  disabled: isSubmitting,
                  class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                  placeholder: _ctx.$t('user_name')
                }), null, 16, ["disabled", "class", "placeholder"])
              ]),
              _: 2
            }, 1032, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_6, [
            _createVNode("label", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('user_surname')) + " ", 1),
              _createVNode(_component_InfoIcon, { text: "user_surname.tooltip" })
            ]),
            _createVNode(_component_VField, {
              name: "surname",
              modelValue: _ctx.me.surname,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.me.surname = $event)),
              rules: "required"
            }, {
              default: _withCtx(({ field, meta: { dirty, valid } }) => [
                _createVNode(_component_InputText, _mergeProps(field, {
                  disabled: isSubmitting,
                  class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                  placeholder: _ctx.$t('user_surname')
                }), null, 16, ["disabled", "class", "placeholder"])
              ]),
              _: 2
            }, 1032, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_7, [
            _createVNode("label", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('generic.email_label')) + " ", 1),
              _createVNode(_component_InfoIcon, { text: "generic.email_label.tooltip" })
            ]),
            _createVNode(_component_VField, {
              name: "email",
              modelValue: _ctx.me.email,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.me.email = $event)),
              rules: "required|email"
            }, {
              default: _withCtx(({ field, meta: { dirty, valid } }) => [
                _createVNode(_component_InputText, _mergeProps(field, {
                  disabled: isSubmitting,
                  class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                  placeholder: _ctx.$t('generic.email_label')
                }), null, 16, ["disabled", "class", "placeholder"])
              ]),
              _: 2
            }, 1032, ["modelValue"])
          ])
        ], 40, ["onSubmit"])
      ]),
      _createVNode("section", _hoisted_8, [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_go_back_label'),
          class: "p-button-secondary",
          icon: "pi pi-arrow-left",
          iconPos: "left",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.back()))
        }, null, 8, ["label"])
      ])
    ]),
    _: 1
  }))
}