import { CalendarRoutesEnum } from '@/modules/calendar/router';
import { POSRoutesEnum } from '@/modules/pos/router';
import { Options, Vue } from 'vue-class-component';
import { CouponsRoutesEnum } from '@/modules/coupons/router';
import { MainRoutesEnum } from '@/router/MainRoutesEnum';
import { Prop } from 'vue-property-decorator';
  
export default class POSMenu extends Vue {
    @Prop({ default: false })
        readonly menuVisible: boolean;

    hideMenu() {
        this.$emit('hideMenu', this.menuVisible);
    }

    goCalendar() {
        this.$router.push({ 
            name: CalendarRoutesEnum.POS_CALENDAR ,
            params: {
                forcustomer: 0
              }
        });
    }

    goBookCustomerService() {
        this.$router.push({ 
            name: CalendarRoutesEnum.POS_CALENDAR,
            params: {
                forcustomer: 1
              }
        });
    }

    goBuyProviderService() {
        this.$router.push({ name: POSRoutesEnum.ADD_PROVIDER_SERVICE });
    }

    goPortfolio() {
        this.$router.push({ name: POSRoutesEnum.PORTFOLIO_LIST });
    }

    goServices() {
        this.$router.push({ name: POSRoutesEnum.SERVICES_LIST });
    }

    goOrders() {
        this.$router.push({ name: POSRoutesEnum.POS_ORDERS });
    }

    goCustomerOrders() {
        this.$router.push({ name: POSRoutesEnum.POS_CUSTOMER_ORDERS });
    }

    goFeedbacks() {
        this.$router.push({ name: POSRoutesEnum.POS_CUSTOMER_FEEDBACKS });
    }

    goIssuedCoupons() {
        this.$router.push({ name: CouponsRoutesEnum.ISSUED_COUPONS });
    }

    goPromoCoupons() {
        this.$router.push({ name: CouponsRoutesEnum.ISSUED_COUPONS });
    }

    goNotifications() {
        this.$router.push({ name: MainRoutesEnum.NOTIFICATIONS });
    }

    goMyCoupons() {
        this.$router.push({ name: CouponsRoutesEnum.COUPONS });
    }

    goGraphs() {
        this.$router.push({ name: POSRoutesEnum.POS_GRAPHS });
    }

    goAddNewService() {
        this.$router.push({ name: POSRoutesEnum.ADD_SERVICE_SELECT });
    }

    goInvoices() {
        this.$router.push({ name: POSRoutesEnum.INVOICES });
    }
}
