import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "dashboard_page",
  class: "page standard"
}
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminDashboard = _resolveComponent("AdminDashboard")!
  const _component_ProviderDashboard = _resolveComponent("ProviderDashboard")!
  const _component_POSDashboard = _resolveComponent("POSDashboard")!
  const _component_TechnicianDashboard = _resolveComponent("TechnicianDashboard")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("section", _hoisted_2, [
      _createVNode("h1", null, _toDisplayString(_ctx.$t('dashboard.title')), 1),
      (_ctx.isSuperAdmin)
        ? (_openBlock(), _createBlock(_component_AdminDashboard, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.isProvider)
        ? (_openBlock(), _createBlock(_component_ProviderDashboard, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.isPOS)
        ? (_openBlock(), _createBlock(_component_POSDashboard, {
            key: 2,
            fromLogin: _ctx.fromLogin
          }, null, 8, ["fromLogin"]))
        : _createCommentVNode("", true),
      (_ctx.isTechnician)
        ? (_openBlock(), _createBlock(_component_TechnicianDashboard, { key: 3 }))
        : _createCommentVNode("", true)
    ])
  ]))
}