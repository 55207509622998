import { Options, Vue } from "vue-class-component";

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';
import { authService } from "@services/auth.service";
import { ChangePasswordData } from "@/model/api/Auth";
import { FormPage } from "@components";

@Options({
  components: {
    VForm,
    VField,
  }
})
export default class ChangePasswordPage extends FormPage {

  model: ChangePasswordData = {} as any;

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  created() {
    this.modelFormChanged();
  }

  async doSave() {
    await this.$waitFor(async () => {
      await authService.changePassword(this.model);
      this.modelFormChanged();

      this.$successMessage(this.$t('data_saved_success'));
      this.$router.back();

    }, this.$t('generic_error'));
  }

}