import { Condition } from '@/model/api/Condition';
import { CRUD } from './base/crud';

class ConditionsService extends CRUD<Condition, any> {
    readonly endPoint = `conditions`;

    public fetchConditions() {
        return this.get<Condition[]>(`${this.endPoint}`);
    }

    public accept() {
        return this.post<Condition[]>(`${this.endPoint}/accept`);
    }

}

export const conditionsService = new ConditionsService();