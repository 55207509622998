import { CouponsRoutesEnum } from '@/modules/coupons/router/CouponsRoutesEnum';
import { POSRoutesEnum } from '@/modules/pos/router/POSRoutesEnum';
import { ProviderServicesRoutesEnum } from '@/modules/services/router/providerPortfolioServices';
import { ServicesRoutesEnum } from '@/modules/services/router';
import { UsersRoutesEnum } from '@/modules/users/router';
import { AdminStatsRoutesEnum } from '@/modules/adminstats/router';
import { Options, Vue } from 'vue-class-component';
import { OrdersRoutesEnum } from '@/modules/orders/router';

@Options({
    name: 'AdminDashboard'
})
export default class AdminDashboard extends Vue {

    goServices() {
        this.$router.push({ name: ServicesRoutesEnum.SERVICES_LIST });
    }

    goUsers() {
        this.$router.push({ name: UsersRoutesEnum.MAIN });
    }

    goOrders() {
        this.$router.push({ name: POSRoutesEnum.POS_ORDERS });
    }

    goCustomerOrders() {
        this.$router.push({ name: POSRoutesEnum.POS_CUSTOMER_ORDERS });
    }

    goIssuedCoupons() {
        this.$router.push({ name: CouponsRoutesEnum.ISSUED_COUPONS });
    }

    goAdmStats() {
        this.$router.push({ name: AdminStatsRoutesEnum.MAIN });
    }

    goPosPerformance() {
        this.$router.push({ name: POSRoutesEnum.POS_CUSTOMER_FEEDBACKS });
    }

    goProviderPerformance() {
        this.$router.push({ name: ProviderServicesRoutesEnum.SERVICES_FEEDBACKS_LIST });
    }

    goPosPenalties() {
        this.$router.push({ name: OrdersRoutesEnum.CANCELED_ORDERS, params: { type: OrdersRoutesEnum.POS_CANCELED } });
    }

    goProviderPenalties() {
        this.$router.push({ name: OrdersRoutesEnum.CANCELED_ORDERS, params: { type: OrdersRoutesEnum.PROVIDER_CANCELED }  });
    }
}
