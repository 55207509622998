import { Options, Vue } from 'vue-class-component';
import AdminDashboard from '../components/AdminDashboard.vue';
import POSDashboard from '../components/POSDashboard.vue';
import ProviderDashboard from '../components/ProviderDashboard.vue';
import { Prop } from "vue-property-decorator/lib/decorators/Prop";
import TechnicianDashboard from '../components/TechnicianDashboard.vue';

@Options({
    components: { 
        AdminDashboard, 
        ProviderDashboard, 
        POSDashboard,
        TechnicianDashboard
    }
})

export default class DashboardPage extends Vue {
    @Prop({ default: 'false' }) fromLogin: string;

    created() {
        
    }

    mounted() {
        this.$store.dispatch("updateNotificationCount")
    }    
}