import { Provider } from '@/model/api/Provider';

import { providerService } from '@services/provider.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { FormPage, ProviderForm, FileUploadDialog } from '@components';

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';
import { Options, Vue } from "vue-class-component";
import { authStore } from '@/modules/auth/store';
import { entityService } from '@services/entity.service';
import { AttachmentIdEnum, AttachmentTypeEnum } from '@/model/enums/AttachmentTypeEnum';
import { IBaseApiAttachmentModel } from '@/model/common/BaseApiModel';
import { AuthRoutesEnum } from '@/modules/auth/router';

@Options({
  components: {
    VForm,
    VField,
    ProviderForm,
    FileUploadDialog
  }
})
export default class ProviderAreaPage extends FormPage {
  attachment_type: AttachmentTypeEnum = AttachmentTypeEnum.BY_PROVIDER_FOR_POS;
  attachment_id: AttachmentIdEnum = AttachmentIdEnum.ENTITY;
  model: Provider = null;
  attachments: IBaseApiAttachmentModel[] = [];

  isLoading = false;
  displayFileUploadDialog: boolean = false;

  get me() {
    return authStore.getters.me;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    this.isLoading = true;
    try {
      this.model = plainToClass(Provider, await providerService.getById(this.me.entity_id));
      this.attachments = await entityService.getAttachments(this.me.entity_id, AttachmentTypeEnum.BY_PROVIDER_FOR_POS);
      this.modelFormChanged();
    } catch (e) {
      this.$router.back();
    }
    this.isLoading = false;
  }

  async doSave() {
    await this.$waitFor(
      async () => {
        await providerService.updatePatch(classToPlain(this.model) as any);
        await authStore.actions.getMe();
        
        this.modelFormChanged();
        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    );
  }

  updateAttachments(attach: any) {
    this.attachments.push(attach);
    this.$successMessage(this.$t('upload_success'));
  }

  maxFileSize() {
    return process.env.MAX_FILE_SIZE || 10000000;
  }

  deleteAttachment(attach: IBaseApiAttachmentModel) {
    this.$waitFor(async () => {
      await entityService.deleteAttachment(attach.id);
      this.attachments = this.attachments.filter(a => a.id != attach.id);

      this.$successMessage(this.$t('upload_deleted_success'));
    });
  }

  goChangePassword() {
    this.$router.push({ name: AuthRoutesEnum.CHANGE_PASSWORD });
  }

  showFileUploadDialog() {
    this.displayFileUploadDialog = true; 
  }
}