import { authStore } from "@/modules/auth/store";
import { Options } from "vue-class-component";

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';

import { User } from "@/model/api/User";
import { authService } from "@services/auth.service";
import { AuthRoutesEnum } from "@/modules/auth/router";
import { FormPage } from "@components";

@Options({
  components: {
    VForm,
    VField,
  }
})
export default class ProfilePage extends FormPage {
  me: User;

  modelFormName = 'me';

  created() {
    this.me = authStore.getters.me;
    this.modelFormChanged();
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async doSave() {
    await this.$waitFor(async () => {
      await authService.updateMe(this.me);
      await authStore.actions.getMe();
      this.modelFormChanged();

      this.$successMessage(this.$t('data_saved_success'));

    }, this.$t('generic_error'));
  }

  goChangePassword() {
    this.$router.push({ name: AuthRoutesEnum.CHANGE_PASSWORD });
  }

}