import { ProviderTechnicianRoutesEnum } from '@/modules/provider-technician/router';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'TechnicianDashboard'
})
export default class TechnicianDashboard extends Vue {

    goAvailabilities() {
        this.$router.push({ name: ProviderTechnicianRoutesEnum.AVAILABILITIES });
    }

    goAppointments() {
        this.$router.push({ name: ProviderTechnicianRoutesEnum.APPOINTMENTS });
    }

}