import { UserRoleEnum } from '@/model/enums/UserRoleEnum';
import POSMenu from '@/modules/app/components/POSMenu.vue';
import { AuthRoutesEnum } from '@/modules/auth/router';
import { authStore } from '@/modules/auth/store';
import { POSRoutesEnum } from '@/modules/pos/router';
import { ProviderTechnicianRoutesEnum } from '@/modules/provider-technician/router';
import { MainRoutesEnum } from '@/router/MainRoutesEnum';
import { Options, Vue } from 'vue-class-component';

@Options({
    components: { 
        POSMenu
    }
})

export default class MainTemplate extends Vue { 
    displayMenu: boolean = false;

    toggleMenu() {
        this.displayMenu = !this.displayMenu;
    }

    closeMenu() {
        this.displayMenu = false;
    }
   
    get me() {
        return authStore.getters.me;
    }

    get nameWithoutAvatar() {
        if (!this.me) return null;
        if (this.me.role == UserRoleEnum.PROVIDER || this.me.role == UserRoleEnum.POS) return this.me.entity.business_name.charAt(0);
        return !this.me.avatar_image_path_url ? `${this.me.name?.charAt(0) || ''}${this.me.surname?.charAt(0) || ''}` : null;
    }

    goLogout() {
        this.closeMenu();
        this.$localStorage.clearToken();
        window.location.reload();
    }

    goProfile() {
        this.closeMenu();
        if (this.me.role == UserRoleEnum.SUPER_ADMIN) {
            this.$router.push({ name: AuthRoutesEnum.PROFILE });
        }

        if (this.me.role == UserRoleEnum.PROVIDER) {
            this.$router.push({ name: AuthRoutesEnum.PROVIDER_AREA });
        }

        if (this.me.role == UserRoleEnum.POS) {
            this.$router.push({ name: POSRoutesEnum.PROFILE_AREA });
        }

        if (this.me.role == UserRoleEnum.PRTEC) {
            this.$router.push({ name: ProviderTechnicianRoutesEnum.APPOINTMENTS });
        }
    }

    goDashboard() {
        this.closeMenu();
        this.$router.push({ name: MainRoutesEnum.DASHBOARD });
    }

    goNotifications() {
        this.closeMenu();
        this.$router.push({ name: MainRoutesEnum.NOTIFICATIONS });
    }

    get count() {
        return this.$store.state.notificationCount;
    }
}